// EXPORTAR A EXCEL MIXIN

//require('script-loader!file-saver');
// var FileSaver = require('file-saver');
import XLSX from 'xlsx'


export default {
  data(){
    return{
      downloadLoading:false,
      // Mensajerias: [],
      // headers: [
      //   { text: 'Mensajeria'  ,  value: 'Nommensajeria' },
      //   { text: 'Contacto'    ,  value: 'Contacto' },
      //   { text: 'Email'       ,  value: 'Email' },
      //   { text: 'Telefono'    ,  value: 'Telefono' }
      // ],
    }
  },
 
  methods: {
    exportarXls(xls) {
      console.log(xls.tHeaders)
      console.log(xls.nomfile)
      this.downloadLoading = true
      import('@/mixins/exportarExcel').then(excel => {
     
        const tHeader = xls.tHeaders
        const filterVal = xls.filterVal
        const list = xls.data
        const data = this.formatJson(filterVal, list)

        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: xls.nomfile,
          autoWidth: true,
          bookType: 'xls'
        })
        this.downloadLoading = false
      })
    },

    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        if (j === 'timestamp') {
          return parseTime(v[j])
        } else {
          return v[j]
        }
      }))
    }

  }
}