<template>
  <v-row>
    <v-col cols="12">
      <v-card>
        <v-card-title primary-title>
          Consulta de Documentos
          <v-spacer />
          
          <v-btn
            color="success"
            small
            @click="consultar()"
          >
            Consultar

            <v-icon small>
              search
            </v-icon>
          </v-btn>
          
          <!-- BOTON DE XLS   -->
          <v-btn
            class="ma-2"
            small
            dark
            color="warning"
            @click="exportar()"
          >
            <v-icon>save_alt</v-icon>
          </v-btn>
          
          <!-- BOTON PDF -->
          <v-btn
            class="ma-2"
            small
            color="secondary"
            @click="crearPDF()"
          >
            <v-icon>print</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-row>
            <!-- FECHA INICIAL -->
            <v-col
              cols="12"
              xl="2"
              lg="2"
              md="3"
              sm="4"
              xs="4"
            >
              <v-menu

                v-model="menu1"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="date1"
                    label="Fecha Inicial"
                    prepend-icon="event"
                    readonly
                    v-on="on"
                  />
                </template>
                
                <v-date-picker
                  v-model="date1"
                  locale="es"
                  @input="menu1 = false"
                />
              </v-menu>
            </v-col>
        
            <!-- FECHA FINAL -->
            <v-col
              cols="12"
              xl="2"
              lg="2"
              md="3"
              sm="4"
              xs="4"
            >
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="date2"
                    label="Fecha Final"
                    prepend-icon="event"
                    readonly
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="date2"
                  locale="es"
                  @input="menu2 = false"
                />
              </v-menu>
            </v-col>

            <!-- TIPODOC  -->
            <v-col
              cols="12"
              xl="2"
              lg="2"
              md="3"
              sm="3"
              xs="3"
            >
              <v-select
                v-model="tipodoc"
                :items="tipodocs"
                label="Tipo documento"
                @change="consultar()"
              />
            </v-col>
          
             
            <!-- BUSCAR -->
            <v-col
              cols="12"
              xl="4"
              lg="4"
              md="3"
              sm="3"
              xs="3"
            >
              <v-text-field
                v-model="search"
                append-icon="search"
                label="Buscar "
                single-line
                hide-details
              />
            </v-col>


            <!-- DATATABLE -->
            <v-col cols="12">
              <v-data-table
                :loading="loading"
                loading-text="Cargando..."
                :headers="headers"
                :items="Docum"
                :items-per-page="longitud"
                class="elevation-1"
                fixed-header
                height="350"
                :search="search"
                hide-default-footer
                dense
              >
                <template v-slot:item="{ item }">
                  <tr @dblclick="edit(item)">
                    <td>{{ item.numdoc }}</td>
                    
                    <!-- <td>{{ item.Tipodoc}}</td> -->
                    <td>{{ item.fecha }}</td>
                    <td align="end">
                      {{ item.importe }}
                    </td>
                    <td align="end">
                      {{ item.descuento }}
                    </td>
                    <td align="end">
                      {{ item.impuesto1 }}
                    </td>
                    <td align="end">
                      {{ item.total }}
                    </td>
                    
                    
                    <td>{{ item.divisa }}</td>
                    <td>{{ item.tc }}</td>
                           
                    <td
                      v-if="item.status == 0"
                      :style="{color: getColor(item.Status) }"
                    >
                      Abierta
                    </td>
                    <td
                      v-if="item.status == 9"
                      :style="{color: getColor(item.Status) }"
                    >
                      Pedida
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
var accounting = require("accounting");
var base64 = require('base-64');
import excelMixin from '@/mixins/excelMixin.js';

import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable';
  


export default {
  mixins:[excelMixin],

  data () {
    return {
      //FECHAS
      date1: new Date().toISOString().substr(0, 10),
      date2: new Date().toISOString().substr(0, 10),
        
      menu1: false,
      menu2: false,

      // SELECTOR TIPODOC
      tipodocs: ['Pedido', 'Cotización'],
      tipodoc: 'Pedido',
      e1:'Pedidos',
      loading: true,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      search: '',
      longitud: 5,
       
      headers: [
        { text: 'ID', align: 'start', value: 'numdoc' },
        // { text: 'Tipo',  align: 'start'  ,           value: 'Tipodoc' },
        { text: 'Fecha',     align: 'start'  ,          value: 'fecha' },
        { text: 'Importe',   align: 'end',  value: 'importe'   },
        { text: 'Descuento',  value: 'descuento' , align: 'end'},
        { text: 'Impuesto',  align: 'end',  value: 'impuesto1' },
        { text: 'Total',     align: 'end',  value: 'total' },
        { text: 'Divisa',    value: 'divisa' ,},
        { text: 'tc',    value: 'tc' ,},


        { text: 'Estatus',    value: 'Status' ,},
      ],

      Docum: []
    }
  },

  computed:{
    ...mapGetters('Login',['getdatosUsuario','getClientes','getModo','getInfoVend']),
     
  },



  created () {
    //Recibir parametro de tipo de Documento a Abrir.
    // console.log("Params",this.$route.params)

    this.tipodocumento= this.$route.params.tipodocumento
      
    // Definir valores iniciales
    // Fecha inicial Primero de mes.
    let date0 = new Date()
    let mes = date0.getMonth()
    let anio = date0.getFullYear()
            
    // console.log(mes, anio)
    this.date1 = new Date(anio, mes, 1).toISOString().substr(0, 10)
    this.consultar()

  },

  methods:{
    ...mapActions('documento',['addDocumento']),



    // FORMATO PDF
    crearPDF() {
      var columns = [
        { title: 'ID',        dataKey: 'Numdoc' },
        { title: 'Fecha' ,    dataKey: 'Fecha' },
        { title: 'Importe',   dataKey: 'importe' },
        { title: 'Descuento', dataKey: 'descuento' },
        { title: 'Iva',       dataKey: 'impuesto1' },
        { title: 'Total',     dataKey: 'total' },
        { title: 'Divisa',    dataKey: 'divisa' },
        { title: 'Tc',        dataKey: 'Tc' },
        { title: 'Estatus',   dataKey: 'Status' },
      ];

      var doc = new jsPDF('p','pt');
      doc.autoTable(columns, this.Docum, {
        styles: {minCellWidth: 50, halign: 'left'},
        columnStyles: {
          0: {cellWidth: 'auto'},
          1: {cellWidth: 'auto'},
          2: {cellWidth: 'auto'},
          3: {cellWidth: 'auto'},
          4: {cellWidth: 'auto'},
              
        },
        margin: {top: 60},
        didDrawPage: function(data) {
          doc.text("Consulta de Documentos", 40, 30);
        }
      });
      doc.save('Documentos.pdf');

    },


    // Consulto todos los clientes
    consultar(){
      this.Docum = []
      // console.log(this.tipodoc)
      var payload = {
        Numvend: '',
        Numcli: '',
        Numalm: '',
        Fecha1: this.date1,
        Fecha2: this.date2,
        Tipodoc: this.tipodoc
      }
    

      if(this.tipodoc == 'Pedido'){
        payload.Tipodoc = ' P'
      }else{
        payload.Tipodoc = ' Q'
      }

      this.loading = true

      // if(this.getModo == 'vendedor'){
      //   payload.numvend = this.getInfoVend.Numvend
      // }else{
      //   payload.numcli = this.getdatosUsuario.numcli
      // }

      console.log("payload",payload)
      this.$http.post('auth/api/v1/condoctipo',payload).then(response => {


        // console.log(response.data)

        //Calcular el Total.
        if(response.data.length > 0){
          console.log(response.data)
          this.Docum = response.data
            
          this.Docum.forEach(element =>{
            element.importe = accounting.unformat(element.importe)
            element.impuesto1 = accounting.unformat(element.impuesto1)
            element.descuento = accounting.unformat(element.descuento)
            element.total     = element.importe - element.descuento + element.impuesto1

            element.importe = accounting.formatNumber(element.importe,2)
            element.impuesto1 = accounting.formatNumber(element.impuesto1,2)
            element.descuento = accounting.formatNumber(element.descuento,2)
            element.total = accounting.formatNumber(element.total,2)
              
              
          })
          this.longitud= this.Docum.length
        }

      }).catch(function(error){
        console.log(error)
      }).finally(()  => this.loading = false)

    },


    edit(tipodoc){

      console.log(tipodoc)
      this.addDocumento(tipodoc)
        
      var encodedData = base64.encode(tipodoc.Iddocum);
      var doc = {doc: encodedData}
      this.$router.push({name:'documento', params: {info: tipodoc, origen: 'cotizacion'}}).catch(err => {console.log(err)})
    },

    getColor (status) {
      if (status == 0) return 'red'
      else if (status == 9) return 'green'
      else return 'green'
    },

    exportar(){
      let xls = {
        data:      this.Docum,
        tHeaders : ['ID doc', 'Nombre', 'Tipo', 'Fecha', 'Total', 'Divisa', 'Estatus',],
        filterVal: ['Numdoc', 'nomcli', 'Tipodoc', 'Fecha', 'importe', 'divisa', 'Status'],
        nomfile :  "Consulta de Documentos"
      }
      // console.log(xls)
      this.exportarXls(xls)
    },


  }
};
</script>